import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CiEdit } from "react-icons/ci";
import { Button } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { checkTheme } from '../../utils/utils';
import { getClubProfile } from '../../api/actions/clubProfile';
import arrowRightIcon from "../../assets/images/common/arrow-right.svg";
import { CREATE_CLUB_PROFILE } from '../../constants/pathname';
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";
import ProfilesTab from './ProfilesTab';
import defaultFacilityIcon from "../../assets/images/common/img1.png";
import { clubHostBooking } from '../../api/actions/club';
import toast from 'react-hot-toast';
import { styled, Switch } from '@mui/material';
const CustomSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 39,
    height: 20,
    padding: 0,
    marginLeft: 5,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        // margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: 'var(--sandy-brown)',
                opacity: 1,
                border: 0,
                ...theme.applyStyles('dark', {
                    backgroundColor: '#2ECA45',
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
            ...theme.applyStyles('dark', {
                color: theme.palette.grey[600],
            }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            backgroundColor: 'var(--sandy-brown)',
            opacity: 0.7,
            ...theme.applyStyles('dark', {
                opacity: 0.3,
            }),
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 20,
        height: 20,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        ...theme.applyStyles('dark', {
            backgroundColor: '#39393D',
        }),
    },
}));
const ClubProfile = () => {
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState({});
    const location = useLocation();
    const { country } = location.state || {};
    const userInfo=JSON.parse(localStorage.getItem('user_info'));
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [pending, setPending] = useState(false)
    const swiperRef = useRef(null);
    const { id } = useParams()
    const [update, setUpdate] = useState(false)
    useEffect(() => {
        getClubProfile({ id, country: country })((response) => {
            if (response && response?.status) {
                setProfileData(response?.data);
            }
        });
    }, []);

    const handleNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };
    useEffect(() => {
        setTimeout(() => {
            handleNext();
        }, 1000);
    }, []);

    const handlePrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const handleSlideChange = (swiper) => {
        setActiveImageIndex(swiper.realIndex); // Use realIndex for loop mode
    };

    const activeImage = profileData?.club_images?.[activeImageIndex];
    const handleSwitchToggle = () => {
        setPending(true)
        clubHostBooking({ club: id, country: country })((res) => {
            if (res?.status) {
                toast.success(res?.message || "Success");
                setUpdate(!update);
                setPending(false)
            } else {
                toast.error(res?.message || "Something went wrong");
                setPending(false)
            }
            setPending(false)
        });
    };

    return (
        <div className='club-profile-page'>
            <div className='clubs-profile myclub-profile-page'>
                          <div className='page-details'>
                              <div className='top-section d-flex align-items-center justify-content-between'>
                                  <div className='left'>Club Profile</div>
                                  <div className='right d-flex align-items-center gap-3'>
          
                                     
                                  </div>
          
                              </div>
                              <div className='bottom-section' style={{ background: "var(--soft-gray)" }}>
                                  <div className='row'>
                                      <div className='col-md-4 swiper-section'>
                                          <div className='club-name'>
                                              {profileData?.club_name || ""}
                                          </div>
                                    
                                              {Array.isArray(profileData?.club_images) && profileData?.club_images?.length > 1  &&
                                                  <>
                                                      <div className="swipet-button">
                                                          <Button className="prev-icon" onClick={handlePrev}>
                                                              <GrPrevious color='black' />
                                                          </Button>
                                                      </div>
                                                      <div className="swipet-button">
                                                          <Button className="next-icon" onClick={handleNext}>
                                                              <GrNext color='black' />
                                                          </Button>
                                                      </div>
                                                  </>}
                                              <MySwiper club_images={profileData?.club_images || []} swiperRef={swiperRef} mainImage={profileData?.main_image} />
                                          {/* <Swiper
                                              modules={[Pagination, Navigation, Autoplay]}
                                              ref={swiperRef}
                                              slidesPerView={1}
                                              className="milestoneSwiper"
                                              loop
                                              navigation={{
                                                  nextEl: '.next-icon',
                                                  prevEl: '.prev-icon',
                                              }}
                                              // onSlideChange={handleSlideChange}
                                              // autoplay={{
                                              //     delay: 2000,
                                              //     disableOnInteraction: true,
                                              // }}
                                          >
                                              {profileData?.club_images && Array.isArray(profileData?.club_images) &&
                                                  profileData?.club_images?.map((item, index) => (
                                                      <SwiperSlide key={item.id}>
                                                          <div className="slide-card">
                                                              <div className='img-wrapper'>
                                                                  <img src={item?.image} alt={`Club Image ${index + 1}`} />
                                                              </div>
                                                          </div>
                                                      </SwiperSlide>
                                                  ))
                                              }
                                              {Array.isArray(profileData?.club_images) && !profileData?.club_images?.length &&
                                                  <SwiperSlide>
                                                      <div className="slide-card mt-2">
                                                          <div className='img-wrapper'>
                                                              <img src={{}} alt={`Club Image`} />
                                                          </div>
                                                      </div>
                                                  </SwiperSlide>
                                              }
          
                                              {Array.isArray(profileData?.club_images) && profileData?.club_images?.length > 1 &&
                                              swiperRef.current && swiperRef.current.swiper &&
                                              <>
                                                  <div className="swipet-button">
                                                      <Button className="prev-icon" onClick={handlePrev}>
                                                          <GrPrevious color='black' />
                                                      </Button>
                                                  </div>
                                                  <div className="swipet-button">
                                                      <Button className="next-icon" onClick={handleNext}>
                                                          <GrNext color='black' />
                                                      </Button>
                                                  </div>
                                              </>}
                                          </Swiper> */}
                                
                                      </div>
                                      <div className='col-md-8 profile-basic-details'>
                                          <div className="info-card">
                                              <div className="row">
                                                  <div className="col-md-4 col-xl-3 item">
                                                      <span className="info-label">Year Founded</span> <span className="info-value">{profileData?.year_founded || ""}</span>
                                                  </div>
                                                  <div className="col-md-4 col-xl-5 item">
                                                      <span className="info-label">Club Type</span> <span className="info-value">{profileData?.club_type || ""}</span>
                                                  </div>
                                                  <div className="col-md-4 col-xl-3 item br-0">
                                                      <span className="info-label">Total Members</span> <span className="info-value">{profileData?.total_members || ""}</span>
                                                  </div>
                                                  <div className="col-md-8 col-xl-12 item br-0" style={{ paddingLeft: "12px", justifyContent: "start" }}>
                                                      <span className="info-label">Website</span>
                                                      <a
                                                          href={profileData?.club_website || ""}
                                                          target='__blank' className="underline-link info-value">{profileData?.club_website || ""}</a>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="info-card mt-4">
                                              <div className="row">
                                                  <div className="col-md-7 item">
                                                      <span className="info-label">Location</span>
                                                      <span className="info-value primary-address" title={profileData?.primary_address || ""}>{profileData?.city_name || ""}</span>
                                                  </div>
                                                  <div className="col-md-5 item br-0">
                                                      <span className="info-label">Phone Number</span>
                                                      <span className="info-value">{profileData?.contact || ""}</span>
                                                  </div>
                                                  <div className={`col-md-7 item  justify-content-start ${(checkTheme()  || !profileData?.golf_booking )? 'br-0' : ''}`}>
                                                      <span className="info-label">Email</span>
                                                      <a href={`mailto:${profileData?.admin_email || ""}`} className="info-value text-decoration-none">{profileData?.admin_email || ""}</a>
                                                  </div>
                                                  {!checkTheme() && profileData?.golf_booking &&
                                                      <div className="col-md-4 item br-0">
                                                          <span className="info-label">Host Booking</span>
                                                          <CustomSwitch checked={profileData?.host_booking} disabled={pending} onChange={(e) => handleSwitchToggle()} />
                                                      </div>}
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className='tabs-panel mt-5'>
                                  <ProfilesTab profileData={profileData} userInfo={userInfo} />
                              </div>
                          </div>
                      </div>
        </div>




    )
};
const MySwiper = ({club_images = [], swiperRef, mainImage}) => {
    return(
        <Swiper
        modules={[Pagination, Navigation, Autoplay]}
        ref={swiperRef}
        slidesPerView={1}
        className="milestoneSwiper"
        loop
        navigation={{
            nextEl: '.next-icon',
            prevEl: '.prev-icon',
        }}
    >
        {club_images.length === 0 ? (
            <SwiperSlide>
                <div className="slide-card mt-2">
                    <div className='img-wrapper'>
                        <img src={mainImage} alt="Club Image" />
                    </div>
                </div>
            </SwiperSlide>
        ) : club_images?.map((item, index) => (
                <SwiperSlide key={item.id}>
                    <div className="slide-card">
                        <div className='img-wrapper'>
                            <img src={item?.image} alt={`Club Image ${index + 1}`} />
                        </div>
                    </div>
                </SwiperSlide>
            ))}
    </Swiper>
    )
}

export default ClubProfile