import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReactComponent as FilterIcon } from "../../assets/images/common/filter-icon.svg";
import { CLUB_PROFILE, CLUB_PROFILES } from '../../constants/pathname';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Select from "react-select";
import Drawer from '@mui/material/Drawer';
import "swiper/css";
import "swiper/css/navigation";
import { useRef } from 'react';
import { getRcRequest, getRequestAcessstatus, getRequestAllAcessstatus, getRequestBlockAcessstatus, listManagenetwork } from '../../api/actions/manageNetwork';
import RequestAlert from './RequestAlert';
import SweetAlert from 'react-bootstrap-sweetalert';
import dummy1 from '../../assets/images/common/img1.png';
import { FaTimes } from 'react-icons/fa';
import { checkTheme } from '../../utils/utils';
import { useFormik } from 'formik';
import { FaLocationDot } from "react-icons/fa6";
import AsyncSelect from "react-select/async";
import {
    GoogleMap,
    Marker,
    useLoadScript,
    DirectionsService,
    DirectionsRenderer,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import { clubTypeListDropDown, listAllClubs } from '../../api/actions/club';
const libraries = ["places"]
const ManageNetworkClubs = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [update, setUpdate] = useState(false);
    const [pending, setPending] = useState(false);
    const [showSweetAlert, setShowSweetAlert] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [searchText, setSearchText] = useState('');
    const [search, setSearch] = useState('');
    const [alertType, setAlertType] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [club, setClub] = useState('');
    const [isBulkSwal, setIsBulkSwal] = useState({ show: false, id: '', type: '' });
    const [memberAccessOptions, setMemberAccessOptions] = useState([
        { label: "Show All Clubs", value: "ShowAllClubs" },
        { label: "Accept Request", value: "AcceptRequest" },
        { label: "Block Request", value: "BlockRequest" }
    ]);
    // Google map
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_API_KEY,
        libraries: libraries,
    });
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);

    const {
        ready: countryReady,
        value: countryValue,
        setValue: setCountryValue,
        suggestions: { status: countryStatus, data: countrySuggestions },
        clearSuggestions: clearCountrySuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            types: ['country'],
        },
    });

    const {
        ready: cityReady,
        value: cityValue,
        setValue: setCityValue,
        suggestions: { status: cityStatus, data: citySuggestions },
        clearSuggestions: clearCitySuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            types: ['(cities)'],
            componentRestrictions: { country: selectedCountries.map(country => country.short_name) },
        },
    });

    const handleCountrySelect = async (address) => {
        setCountryValue('', false);
        clearCountrySuggestions();

        try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            const country = results[0].address_components.find(component => component.types.includes('country'));

            // Check if country already exists in selectedCountries
            const countryExists = selectedCountries.some(c => c.short_name === country.short_name);

            if (!countryExists) {
                setSelectedCountries(prev => [...prev, {
                    name: country.long_name,
                    short_name: country.short_name,
                    lat,
                    lng,
                }]);

                // Update formik values
                formik.setFieldValue('countries', [...formik.values.countries, country.short_name]);
            }
        } catch (error) {
            console.error("Error fetching geocode:", error);
        }
    };

    const handleCitySelect = async (address) => {
        setCityValue('', false);
        clearCitySuggestions();

        try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            const city = results[0].address_components.find(component => component.types.includes('locality'));

            // Check if city already exists in selectedCities
            const cityExists = selectedCities.some(c => c.name === city.long_name);

            if (!cityExists) {
                setSelectedCities(prev => [...prev, {
                    name: city.long_name,
                    lat,
                    lng,
                }]);

                // Update formik values
                formik.setFieldValue('cities', [...formik.values.cities, city.long_name]);
            }
        } catch (error) {
            console.error("Error fetching geocode:", error);
        }
    };

    const removeCountry = (shortName) => {
        setSelectedCountries(prev => prev.filter(country => country.short_name !== shortName));
        formik.setFieldValue('countries', formik.values.countries.filter(c => c !== shortName));
    };

    const removeCity = (cityName) => {
        setSelectedCities(prev => prev.filter(city => city.name !== cityName));
        formik.setFieldValue('cities', formik.values.cities.filter(c => c !== cityName));
    };

    const {
        ready: searchReady,
        value: searchValue,
        setValue: setSearchValue,
        suggestions: { status: searchStatus, data: searchSuggestions },
        clearSuggestions: clearSearchSuggestions,
    } = usePlacesAutocomplete();

    const handleInputChange = (e) => {
        setSearchValue(e.target.value);
        if (e.target.value === "") {
            clearSearchSuggestions();
        }
    };
    useEffect(() => {
        clubTypeListDropDown({
            search_param: ""
        })((response) => {
            if (response && response?.status) {
                setClubTypeOptions(response?.data?.results);
            }
        })
    }, []);
    const handleSearch = (value) => {
        setSearch(value);
        setSearchText(value);
    };

    useEffect(() => {
        if (search.length >= 3) {
            const debounceTimer = setTimeout(() => {
                setDebouncedSearch(search);
                setData([]);
                setPage(1);
            }, 500);

            return () => clearTimeout(debounceTimer);
        } else if (search.length === 0 && debouncedSearch !== '') {
            setDebouncedSearch('');
            setData([]);
            setPage(1);
        }
    }, [search, debouncedSearch]);

    const clearSearch = () => {
        setSearch('');
        setSearchText('');
        setDebouncedSearch('');
        setPage(1);
        setData([]);
        setUpdate(!update);
    };

    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    const clubId = userInfo?.club_details?.[0]?.club_id;
    const countryId = userInfo?.club_details?.[0]?.country;
    const isSuperAdmin = userInfo?.is_superuser;

    useEffect(() => { getListData(); }, [page, limit, debouncedSearch, update]);

    const getListData = () => {
        setPending(true);

        if (debouncedSearch) {
            setPage(1);
        }

        const selectedClubId = formik.values.club;
        const selectedCountryId = formik.values.country;

        listManagenetwork({
            page: debouncedSearch ? 1 : page,
            limit,
            search: debouncedSearch,
            club: selectedClubId || clubId,
            country: selectedCountryId || countryId,
            country_name: selectedCountries.map(country => country.name).join(','),
            city: selectedCities.map(city => city.name).join(','),
            club_type: formik.values.club_type,
            member_access: formik.values.member_access
        })(
            (response) => {
                if (response && response.status) {
                    setData(response?.data?.results);
                    setTotalRows(response?.data?.count);
                    setPending(false);
                }
            }
        );
    };
    const [clubTypeOptions, setClubTypeOptions] = useState([]);
    const [filter, setFilter] = useState({
        status_club: "",
        club_type: "",
        location: ""
    })

    const formik = useFormik({
        initialValues: {
            club_type: "",
            club_type_option: null,
            member_access: "",
            member_access_option: null,
            countries: [],
            cities: [],
            club: "",
            country: "",
            selectedClub: null
        },
        onSubmit: (values) => {
            setShowDrawer(false);
            // Trigger data fetch with new filters
            setUpdate(!update);
        }
    });

    const clearFilters = () => {
        setSelectedCountries([]);
        setSelectedCities([]);
        formik.resetForm();
        setCountryValue('');
        setCityValue('');

        setUpdate(!update);
    };
    const handleSelect = async (address) => {
        let cityInfo = "";
        if (address) {
            let cityArr = address?.split(",");
            cityInfo = cityArr[0];
        }

        setSearchValue(address, false);
        clearSearchSuggestions();

        try {
            // Update formik state with new latitude and longitude
            formik.setValues({
                ...formik.values,
                location: cityInfo
            });
        } catch (error) {
            console.error("Error fetching geocode:", error);
        }
    };
    const toggleActive = (row) => {
        setIsBulkSwal({ show: true, id: row.network_club, row, type: 'update' });
    };

    const handleUpdateClubInfo = (row) => {
        const { id, network_club, club_name } = row;
    
        getRequestAcessstatus({ 
            network_club: id, 
            club: formik.values.club || clubId, 
            country: formik.values.country || countryId 
        })(
            (response) => {
                if (response?.status) {
                    const updatedData = data.map(item =>
                        item.network_club === network_club ? { ...item, is_active: response?.is_active } : item
                    );
                    setUpdate(!update);
                }
            }
        );
    }
    const handleAcceptAll = () => {
        setAlertType('acceptAll');
        setShowSweetAlert(true);
    };

    const handleBlockAll = () => {
        setAlertType('blockAll');
        setShowSweetAlert(true);
    };

    let typingTimer;
    const loadOptionsClub = inputValue => {
        return new Promise((resolve, reject) => {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {
                if (inputValue.length > 0) {
                    listAllClubs({ page: 1, limit: 10, search: inputValue })(response => {
                        if (response?.status) {

                            resolve(response?.data?.results?.map(item => ({
                                value: item.club,
                                label: item.club_name,
                                country: item.country
                            })));
                            setClub(response?.data?.results)

                        }
                    })
                }
                else {
                    resolve([])
                }
            }, 300);

        })
    };
    useEffect(() => {
        listAllClubs({
            page,
            limit: limit
        })((res) => {
            if (res?.status) {
                setClub(res?.data.results?.map(item => ({
                    value: item.club,
                    label: item.club_name,
                    country: item.country
                })));
            }
        })
    }, [])

    const ClubCard = ({ club, selectedCountryId, selectedClubId }) => {
        console.log(selectedCountryId, selectedClubId);

        const [reservationActive, setReservationActive] = useState(
            club.rc_restrict_status?.restriction_enabled || false
        );

        const formik = useFormik({
            initialValues: {
                daily: club.rc_restrict_status?.daily || "",
                weekly: club.rc_restrict_status?.weekly || "",
                monthly: club.rc_restrict_status?.monthly || "",
            },
        });

        const handleInputChange = (e) => {
            const { name, value } = e.target;
            formik.setFieldValue(name, value);
        };

        const filterValues = (values) => {
            const filteredValues = {};
            for (const key in values) {
                if (values[key] !== "") {
                    filteredValues[key] = values[key];
                }
            }
            return filteredValues;
        };

        const debounce = (func, delay) => {
            let timer;
            return function (...args) {
                clearTimeout(timer);
                timer = setTimeout(() => func.apply(this, args), delay);
            };
        };

        const updateRestrictions = debounce((values) => {
       
            if (!checkTheme()) {
                const filteredValues = filterValues(values);
    
                getRcRequest({
                    club: clubId || selectedClubId,
                    country: countryId || selectedCountryId,
                    restrict_club: club.id,
                    restriction_enabled: reservationActive,
                    ...filteredValues,
                })((response) => {
                    if (response.status) {
                        // Handle success
                    } else {
                        console.error("Failed to update restrictions");
                    }
                });
            }
        }, 1000);

        useEffect(() => {
            if (reservationActive) {
                updateRestrictions(formik.values);
            }
        }, [formik.values.daily, formik.values.weekly, formik.values.monthly]);

        useEffect(() => {
            updateRestrictions(formik.values);
        }, [reservationActive]);

        const handleToggle = () => {
            const newReservationActive = !reservationActive;
            setReservationActive(newReservationActive);

            if (!newReservationActive) {
                formik.setValues({
                    daily: "",
                    weekly: "",
                    monthly: "",
                });
            }
        };
        const handleNavigate = () => {
            navigate(`${CLUB_PROFILES}${club.club}`, { state: { country: club?.country } });
        };
        console.log(club?.country);


        if (!checkTheme()) {
            return (
                <div className="col-md-6 col-lg-4">

                    <div className="club-cardpcw">
                        <div className="card-inner">
                            <div className="club-logo">
                                <img
                                    src={club.main_image || dummy1}
                                // alt={club.club_name}
                                />
                            </div>
                            <div className="club-info">
                                <h5 className="club-name">  <span className="cursor-pointer" onClick={handleNavigate}>
                                    {club.club_name}
                                </span></h5>
                                <p className="club-location">
                                    <FaLocationDot />
                                    {/* {club.city}{(club?.city && club?.country_name) ? "," :''} {club.country_name} */}
                                    {club.city_name}
                                </p>
                            </div>
                        </div>
                        <div className="reservation-container row">
                            <div className="reservation-header col-md-3">
                                <span>Restrict Reservations:</span>
                                <div
                                    className={`toggle-switch ${reservationActive ? "active" : ""}`}
                                    onClick={handleToggle}
                                >
                                    <div className="toggle-button"></div>
                                </div>
                            </div>

                            <div className="reservation-inputs col-md-9">
                                <div className="input-group">
                                    <label>Daily</label>
                                    <input
                                        type="number"
                                        name="daily"
                                        value={formik.values.daily}
                                        onChange={handleInputChange}
                                        disabled={!reservationActive}
                                        placeholder='-'
                                    />
                                </div>

                                <div className="input-group">
                                    <label>Weekly</label>
                                    <input
                                        type="number"
                                        name="weekly"
                                        value={formik.values.weekly}
                                        onChange={handleInputChange}
                                        disabled={!reservationActive}
                                        placeholder='-'
                                    />
                                </div>

                                <div className="input-group">
                                    <label>Monthly</label>
                                    <input
                                        type="number"
                                        name="monthly"
                                        value={formik.values.monthly}
                                        onChange={handleInputChange}
                                        disabled={!reservationActive}
                                        placeholder='-'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="col-md-6 col-lg-4" key={club.network_club}>
                    <div className="club-card">
                        <div className="card-inner d-flex">
                            <div className="club-logo">
                                <img src={club.main_image || dummy1} alt={club.club_name} />
                            </div>
                            <div className="club-info">
                                <h5 className="club-name"><Link
                                    to={`${CLUB_PROFILES}${club.club}`}
                                    state={{ country: club.country }}
                                >
                                    {club.club_name}
                                </Link></h5>
                                <p className="club-location">
                                    <FaLocationDot />
                                    {/* {club.city}, {club.country_name} */}
                                    {club.city_name}
                                </p>
                                <p className="club-type">{club.club_type} Club</p>
                            </div>
                        </div>
                        <div className="request-status">
                            <div className="request-label">Request</div>
                            <div className="accept-indicator">Accept</div>
                            <div className="toggle-switch-vertical">
                                <div
                                    className={`toggle-track ${!club.network_block_status ? 'active' : ''}`}
                                    onClick={() => toggleActive(club)}
                                >
                                    <div className="toggle-button"></div>
                                </div>
                            </div>
                            <div className="block-indicator">Block</div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        <div className='member-stats-section'>
            <Drawer
                anchor={'right'}
                open={showDrawer}
                onClose={() => setShowDrawer(false)}
                className='member-stats-drawer'
                sx={{
                    '& .MuiPaper-root': {
                        padding: '30px 25px',
                        height: "100vh",
                        overflow: "hidden"
                    },
                    '& .MuiDrawer-paper': {
                        width: '420px'
                    },
                    '& .MuiBox-root': {
                        width: '420px'
                    },
                    '& .MuiList-root': {
                        display: 'flex',
                        columnGap: '10px',
                        width: '90%',
                        margin: 'auto',
                        paddingTop: "25px"
                    },
                    '& .MuiButtonBase-root': {
                        padding: '7px',
                        textAlign: 'center',
                        alignItems: 'center',
                        borderRadius: '10px',
                        backgroundColor: '#F5F5F5',
                    }
                }}
            >
                <div className="drawer-top-box">
                    <div>Filter</div>
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowDrawer(false)}></button>
                </div>
                <div className="drawer-bottom-box mt-4">
                    <Form onSubmit={formik.handleSubmit}>


                        <div className='item'>
                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em5">
                                <Form.Label>Club Type</Form.Label>
                                <div className='input-border'>
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            colors: {
                                                ...theme.colors,
                                            },
                                        })}
                                        isClearable
                                        options={clubTypeOptions}
                                        value={formik.values?.club_type_option}
                                        onChange={(option) => {
                                            formik.setValues({
                                                ...formik.values,
                                                club_type: option?.value,
                                                club_type_option: option
                                            })
                                        }}
                                        placeholder="Select"
                                        isSearchable
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                width: '230px', // Ensures a minimum width
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                width: '230px', // Ensures dropdown has enough space
                                            })
                                        }}
                                    />
                                </div>
                            </Form.Group>


                        </div>
                        {checkTheme() && (<div className='item'>
                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="memberAccessInput">
                                <Form.Label>Member Access</Form.Label>
                                <div className='input-border'>
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            colors: {
                                                ...theme.colors,
                                            },
                                        })}
                                        isClearable
                                        options={memberAccessOptions}
                                        value={formik.values?.member_access_option}
                                        onChange={(option) => {
                                            formik.setValues({
                                                ...formik.values,
                                                member_access: option?.value,
                                                member_access_option: option
                                            })
                                        }}
                                        placeholder="Select"
                                        isSearchable
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                width: '230px', // Ensures a minimum width
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                width: '230px', // Ensures dropdown has enough space
                                            })
                                        }}
                                    />
                                </div>
                            </Form.Group>
                        </div>)}

                        <div className='item'>
                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="country" style={{ position: "relative" }}>
                                <Form.Label>Country</Form.Label>
                                <div className='input-border'>
                                    <Form.Control
                                        type="text"
                                        placeholder="Search country"
                                        name="country"
                                        value={countryValue}
                                        onChange={(e) => setCountryValue(e.target.value)}
                                    />
                                    {countryValue !== "" && countryStatus === "OK" && (
                                        <ul
                                            style={{
                                                listStyleType: "none",
                                                padding: "0",
                                                margin: "8px 0",
                                                backgroundColor: "#fff",
                                                border: "1px solid #ddd",
                                                borderRadius: "4px",
                                                position: "absolute",
                                                width: "235px",
                                                right: "0% !important",
                                                left: "auto",
                                                top: "81%",
                                                maxHeight: "200px",
                                                overflowY: "auto",
                                                zIndex: 1000,
                                            }}
                                        >
                                            {countrySuggestions.map(({ place_id, description }) => (
                                                <li
                                                    key={place_id}
                                                    onClick={() => handleCountrySelect(description)}
                                                    style={{
                                                        padding: "10px",
                                                        cursor: "pointer",
                                                        borderBottom: "1px solid #eee",
                                                        backgroundColor: "#fff",
                                                        transition: "background-color 0.2s",
                                                    }}
                                                    onMouseEnter={(e) =>
                                                        (e.target.style.backgroundColor = "#f0f0f0")
                                                    }
                                                    onMouseLeave={(e) =>
                                                        (e.target.style.backgroundColor = "#fff")
                                                    }
                                                >
                                                    {description}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </Form.Group>

                            {/* Selected Countries Display */}
                            {selectedCountries.length > 0 && (
                                <div style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "8px",
                                    marginTop: "10px",
                                    marginBottom: "15px"
                                }}>
                                    {selectedCountries.map((country) => (
                                        <div
                                            key={country.short_name}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                backgroundColor: "#e9f0ff",
                                                padding: "5px 10px",
                                                borderRadius: "20px",
                                                fontSize: "14px"
                                            }}
                                        >
                                            <span>{country.name}</span>
                                            <FaTimes
                                                style={{
                                                    marginLeft: "5px",
                                                    cursor: "pointer",
                                                    fontSize: "12px"
                                                }}
                                                onClick={() => removeCountry(country.short_name)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className='item'>
                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="city" style={{ position: "relative" }}>
                                <Form.Label>City</Form.Label>
                                <div className='input-border'>
                                    <Form.Control
                                        type="text"
                                        placeholder="Search city"
                                        name="city"
                                        value={cityValue}
                                        onChange={(e) => setCityValue(e.target.value)}
                                    // disabled={selectedCountries.length === 0}
                                    />
                                    {cityValue !== "" && cityStatus === "OK" && (
                                        <ul
                                            style={{
                                                listStyleType: "none",
                                                padding: "0",
                                                margin: "8px 0",
                                                backgroundColor: "#fff",
                                                border: "1px solid #ddd",
                                                borderRadius: "4px",
                                                position: "absolute",
                                                width: "235px",
                                                right: "0% !important",
                                                left: "auto",
                                                top: "81%",
                                                maxHeight: "200px",
                                                overflowY: "auto",
                                                zIndex: 1000,
                                            }}
                                        >
                                            {citySuggestions.map(({ place_id, description }) => (
                                                <li
                                                    key={place_id}
                                                    onClick={() => handleCitySelect(description)}
                                                    style={{
                                                        padding: "10px",
                                                        cursor: "pointer",
                                                        borderBottom: "1px solid #eee",
                                                        backgroundColor: "#fff",
                                                        transition: "background-color 0.2s",
                                                    }}
                                                    onMouseEnter={(e) =>
                                                        (e.target.style.backgroundColor = "#f0f0f0")
                                                    }
                                                    onMouseLeave={(e) =>
                                                        (e.target.style.backgroundColor = "#fff")
                                                    }
                                                >
                                                    {description}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </Form.Group>

                            {/* Selected Cities Display */}
                            {selectedCities.length > 0 && (
                                <div style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "8px",
                                    marginTop: "10px",
                                    marginBottom: "15px"
                                }}>
                                    {selectedCities.map((city) => (
                                        <div
                                            key={city.name}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                backgroundColor: "#e9f0ff",
                                                padding: "5px 10px",
                                                borderRadius: "20px",
                                                fontSize: "14px"
                                            }}
                                        >
                                            <span>{city.name}</span>
                                            <FaTimes
                                                style={{
                                                    marginLeft: "5px",
                                                    cursor: "pointer",
                                                    fontSize: "12px"
                                                }}
                                                onClick={() => removeCity(city.name)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className='submit-box d-flex align-items-center justify-content-end gap-3' style={{ position: "absolute", bottom: "30px", right: "25px" }}>
                            <button
                                className='btn-type-2 cursor-pointer'
                                type="button"
                                onClick={clearFilters}
                            >
                                Clear
                            </button>
                            <button className='btn-type-1' type="submit">Submit</button>
                        </div>
                    </Form>
                </div>
            </Drawer>

            {showSweetAlert && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    confirmBtnCssClass="custom-confirm-button-class"
                    cancelBtnCssClass="custom-cancel-button-class"
                    title={
                        <div style={{ lineHeight: "27px" }}>
                            <span className="custom-sweetalert-title" style={{ lineHeight: "27px" }}>
                                {alertType === 'acceptAll'
                                    ? "Are you sure you want to accept request from members of all clubs?"
                                    : "Are you sure you want to block requests from members of all clubs?"}
                            </span>
                        </div>
                    }
                    onConfirm={() => {
                        setShowSweetAlert(false);
                        if (alertType === 'acceptAll') {
                            // Use selected club and country ID for the accept all API call
                            getRequestAllAcessstatus({
                                club: formik.values.club || clubId,
                                country: formik.values.country || countryId
                            })(
                                (response) => {
                                    if (response?.status) {
                                        setUpdate(!update);
                                    }
                                }
                            );
                        } else if (alertType === 'blockAll') {
                            // Use selected club and country ID for the block all API call
                            getRequestBlockAcessstatus({
                                club: formik.values.club || clubId,
                                country: formik.values.country || countryId
                            })(
                                (response) => {
                                    if (response?.status) {
                                        setUpdate(!update);
                                    }
                                }
                            );
                        }
                    }}
                    onCancel={() => setShowSweetAlert(false)}
                    focusCancelBtn
                />
            )}

            <RequestAlert
                showSweetBulkAlert={isBulkSwal}
                setShowBulkAlert={setIsBulkSwal}
                handleUpdateClubInfo={handleUpdateClubInfo}
            />
            <div className='page-details'>
                <div className='top-section d-flex align-items-center justify-content-between'>
                    <div className='left'>{checkTheme() ? 'Manage Network Clubs' : 'Manage Reciprocal Clubs'}</div>
                    <div className='right d-flex align-items-center gap-3'>
                        <div className='search-box'>
                            <input
                                type='text'
                                placeholder='Search '
                                className='form-control'
                                value={searchText}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                            {searchText && (
                                <FaTimes className='clear-icon' onClick={clearSearch} />
                            )}
                        </div>
                        <div className='filter-box d-flex align-items-center gap-3 cursor-pointer' onClick={() => setShowDrawer(true)}>
                            <FilterIcon />
                            <span>Filter</span>
                        </div>
                        {checkTheme() && (
                            <>
                                <button className='btn-type-5' type="button" onClick={handleAcceptAll}>Accept All</button>
                                <button className='btn-type-5' type="button" onClick={handleBlockAll}>Block All</button>
                            </>
                        )}
                    </div>
                </div>
                {isSuperAdmin && (
                    <div className='col-xl-4 col-md-4 mb-4 mt-4' style={{ paddingLeft: "24px" }}>
                        <Form.Group controlId="club">

                            <AsyncSelect
                                loadOptions={loadOptionsClub}
                                defaultOptions={club}
                                classNamePrefix="select"
                                isClearable={true}
                                placeholder={'Select Club'}
                                onChange={(selectedOption) => {
                                    console.log(selectedOption, "||||SELE");
                                    formik.setFieldValue("club", selectedOption ? selectedOption.value : "");
                                    formik.setFieldValue("country", selectedOption ? selectedOption.country : "");
                                    formik.setFieldValue("selectedClub", selectedOption);
                                    formik.setFieldValue("club_name", selectedOption?.label || "");
                                    setUpdate(!update);
                                }}
                                value={formik.values?.selectedClub}
                                styles={{
                                    control: (styles, { isFocused }) => ({
                                        ...styles,
                                        maxHeight: '31px',
                                        border: formik.errors.club && formik.touched.club
                                            ? '1px solid #dc3545'
                                            : '1px solid var(--light-grayish)',
                                        outline: 'none',
                                        boxShadow: isFocused ? '0 0 0 1px var(--light-grayish)' : 'none',
                                        color: 'var(--medium-gray)',
                                        fontSize: '15px',
                                        '&:hover': {
                                            border: formik.errors.club && formik.touched.club
                                                ? '1px solid #dc3545'
                                                : '1px solid var(--light-grayish)',
                                        },
                                    }),
                                    valueContainer: (styles) => ({
                                        ...styles,
                                        alignItems: 'center',
                                        padding: '3px 8px',
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: 'var(--medium-gray)',
                                        fontSize: '15px',
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        border: '1px solid var(--light-grayish)',
                                        boxShadow: 'none',
                                    }),
                                    placeholder: (styles) => ({
                                        ...styles,
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        color: 'var(--medium-gray)',
                                        fontSize: '15px',
                                    }),
                                }}
                            />

                            {formik.touched.club && formik.errors.club && <span className='required'>{formik.errors.club}</span>}
                        </Form.Group>
                    </div>
                )}


                <div className='table-box mt-3'>
                    {pending ? (
                        <div className="loading-message text-center my-5">
                            <p>Loading...</p>
                        </div>
                    ) : (
                        <>
                            {data?.length > 0 ? (
                                <div className="club-cards-container">
                                    <div className="row g-4">
                                        {data?.map((club) => (
                                            <ClubCard key={club.network_club} club={club} selectedClubId={formik.values.club}
                                                selectedCountryId={formik.values.country} />
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div className="no-data-message text-center my-5">
                                    <p>No clubs found.</p>
                                </div>
                            )}

                            {data?.length > 0 && (
                                <nav aria-label="Page navigation example" className="mt-4">
                                    <ul className="pagination justify-content-center">
                                        <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                                            <button
                                                className="btn btn-type-5"
                                                onClick={() => setPage(page - 1)}
                                                disabled={page === 1}
                                            >
                                                Previous
                                            </button>
                                        </li>
                                        {[...Array(Math.ceil(totalRows / limit)).keys()].map((pageNumber) => (
                                            <li
                                                key={pageNumber + 1}
                                                className={`page-item ${page === pageNumber + 1 ? 'active' : ''}`}
                                            >
                                                <button
                                                    className="btn " onClick={() => setPage(pageNumber + 1)}
                                                >
                                                    {pageNumber + 1}
                                                </button>
                                            </li>
                                        ))}
                                        <li className={`page-item ${page * limit >= totalRows ? 'disabled' : ''}`}>
                                            <button
                                                className="btn btn-type-5"
                                                onClick={() => setPage(page + 1)}
                                                disabled={page * limit >= totalRows}
                                            >
                                                Next
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div >
    );
};

export default ManageNetworkClubs;