import React, { useEffect, useState } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { addAffiliatedClubs, affiliatedListingClub, listAllClubs } from '../../api/actions/club';
import toast from 'react-hot-toast';
import AsyncSelect from 'react-select/async';
import { CLUB_ONBOARDIN } from '../../constants/pathname';

const selectStyle = {
    control: (styles, { isFocused }) => ({
      ...styles,
      borderRadius: '5px',
      maxHeight: "40px",
      width:"auto",
      minHeight: "30px",
      minWidth:"250px",
      boxShadow: 'none',
      fontSize: "15px",
      backgroundColor: 'white',
      borderColor: isFocused ? 'gray' : styles.borderColor,
    }),
    placeholder: (styles) => ({
      ...styles,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      fontSize: "15px"
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: 'white',
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? 'lightgray' : 'white',
      color: 'black',
    }),
  };

const AffiliatedClubs = () => {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [limit, setLimit] = useState(50);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [pending, setPending] = useState(false);
    const [selectedClubsMap, setSelectedClubsMap] = useState({});
    const [club, setclub] = useState(null);
    const [clubDropdown, setClubdropdown] = useState([]);
    // Reciprocal and Unselected Ids
    const [reciprocalIds, setReciprocalIds] = useState([]);
    const [unselectedIds, setUnselectedIds] = useState([]);
    
    console.log('wanted_data', Object.keys(selectedClubsMap))

    const userInfo = JSON.parse(localStorage.getItem('user_info'));

    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);

    const country = searchParams.get("country");
    const value = searchParams.get("value");
    const label = searchParams.get("label");    

    // Get array of selected clubs from the map
    const getSelectedClubsArray = () => {
        return Object.values(selectedClubsMap);
    };

    // Check if submit button should be disabled
    const isSubmitDisabled = () => {
        // For superusers, check if both a club is selected and at least one affiliated club is selected
        if (userInfo?.is_superuser) {
            return !club || getSelectedClubsArray().length === 0;
        }
        // For non-superusers, just check if at least one affiliated club is selected
        return getSelectedClubsArray().length === 0;
    };

    useEffect(() => {
        if (country && value && label) {
          
          const formattedClub = {
            value: value,
            label: label,
            country: country
          };
          
          setclub(formattedClub);
          
        //   getListData();
        }
      }, [country,value,label]); 

    useEffect(() => {
        if (search.length >= 0) {
            const debounceTimer = setTimeout(() => {
                setDebouncedSearch(search);
                setPage(1);
                // setData([]);
            }, 500);
            return () => clearTimeout(debounceTimer);
        } else {
            setDebouncedSearch('');
        }
    }, [search]);

    // useEffect(() => {
    //     // Reset data and page when club changes or when searching
    //     setData([]);
    //     setPage(1);
        
    //     // Only clear selections when the club changes, not when searching
    //     if (club !== null && !debouncedSearch) {
    //         setSelectedClubsMap({}); // Clear selected clubs only when changing the main club
    //     }
        
    //     getListData();
    // }, [debouncedSearch, club]);
    // Handle club changes
    useEffect(() => {
        if (club !== null) {
            setData([]);
            setPage(1);
            setSelectedClubsMap({}); // Clear selected clubs when changing the main club
            getListData();
        }
    }, [club]);

    // Handle search changes
    useEffect(() => {
        if (debouncedSearch !== undefined) {
            // setData([]);
            setPage(1);
            // Don't clear selections when searching
            getListData();
        }
    }, [debouncedSearch]);

    const handleSubmit = () => { 

        if (isSubmitDisabled()) {
            toast.error('Please select a club and at least one affiliated club');
            return;
        }

        let clubData = getSelectedClubsArray().map(club => (typeof club === 'object' ? club.id || club.club : club));
        // Unselected ids
        let unSelectedArr = [];

        if(Array.isArray(unselectedIds) && unselectedIds?.length){
            unSelectedArr = unselectedIds.filter(item => reciprocalIds.includes(item))
        }        
        
        if(Array.isArray(reciprocalIds) && reciprocalIds?.length){
            clubData = clubData.filter(item => !reciprocalIds.includes(item) ) 
        }

        setLoading(true);
        addAffiliatedClubs({
            country: userInfo?.is_superuser ? club?.country : userInfo?.club_details[0]?.country,
            club: userInfo?.is_superuser ? club?.value : userInfo?.club_details[0]?.club_id,
            // reciprocal_club: clubData
            selected_ids: clubData,
            unselected_ids: unSelectedArr || []
        })((res) => {
            if (res?.status) {
                console.log('Successfully added affiliated clubs:', res);
                toast.success('Affiliated clubs added successfully');
            } else {
                console.error('Failed to add affiliated clubs:', res);
                toast.error('Failed to add affiliated clubs');
            }
            setLoading(false);
        });
    };   

    const getListData = () => {
        setPending(true);
        if (userInfo?.is_superuser) {
            listAllClubs({
                page,
                limit: limit,
                club: userInfo?.is_superuser ? club?.value || '': userInfo?.club_details[0]?.club_id,
                country: userInfo?.is_superuser ? club?.country || '': userInfo?.club_details[0]?.country,
                search: debouncedSearch,
                from_date: fromDate ? dayjs(fromDate).format('YYYY-MM-DD') : '',
                to_date: toDate ? dayjs(toDate).format('YYYY-MM-DD') : ''
            })(response => {
                if (response?.status) {
                    // Reciprocal ids list
                    setReciprocalIds(response?.reciprocal_ids || []);
                    // Set the data directly
                    setData(response?.data.results);
                    setTotalRows(response?.data.count);
                    setClubdropdown(
                        response?.data.results
                            ?.map(item => ({
                                value: item.club,
                                label: item.club_name,
                                country: item.country
                            }))
                            .sort((a, b) => a.label.localeCompare(b.label)) 
                    );
                    
                    // Initialize selected clubs from the response for first load only
                    if (page === 1 && Object.keys(selectedClubsMap).length === 0) {
                        const initialSelectedClubs = {};                        

                        response?.data?.results.filter(club => club?.is_reciprocal_club).forEach(club => {
                            initialSelectedClubs[club.club] = club;
                        });
                        setSelectedClubsMap(initialSelectedClubs);
                    }
                    
                    setHasMore(response.data.results.length > 0);
                }
                setPending(false);
            });
        } else {
            affiliatedListingClub({
                page,
                limit: limit,
                id: userInfo?.is_superuser ? club?.value || '': userInfo?.club_details[0]?.club_id,
                country: userInfo?.is_superuser ? club?.country || '': userInfo?.club_details[0]?.country,
                search: debouncedSearch,
            })(response => {
                if (response?.status) {
                    // Reciprocal ids list
                    setReciprocalIds(response?.reciprocal_ids || []);
                    // Set the data directly
                    setData(response?.data.results);
                    setTotalRows(response?.data.count);
                    setClubdropdown(response?.data.results?.map(item => ({
                        value: item.club,
                        label: item.club_name,
                        country: item.country
                    })));
                    
                    // Initialize selected clubs from the response for first load only
                    console.log("second load",response?.data?.results)
                    if (page === 1 && Object.keys(selectedClubsMap).length === 0) {
                        const initialSelectedClubs = {};

                        response?.data?.results.filter(club => club?.is_reciprocal_club).forEach(club => {
                            initialSelectedClubs[club.club] = club;
                        });
                        setSelectedClubsMap(initialSelectedClubs);
                    }
                    
                    setHasMore(response.data.results.length > 0);
                }
                setPending(false);
            });
        }
    };


    const loadMoreClubs = () => {
        if (!hasMore) return;
        console.log("Fetching more data...");
        if(userInfo?.is_superuser) {
            listAllClubs({
                page: page + 1,
                limit: limit,
                club: userInfo?.is_superuser ? club?.value || '' : userInfo?.club_details[0]?.club_id,
                country: userInfo?.is_superuser ? club?.country || '': userInfo?.club_details[0]?.country,
                search: debouncedSearch,
            })(response => {
                if (response?.status) {
                    // Reciprocal ids list
                    setReciprocalIds(response?.reciprocal_ids || []);
                    // Here we keep the append behavior for pagination
                    setData(prevData => [...prevData, ...response?.data?.results]);
                    setHasMore(response?.data?.results?.length > 0);
                    
                    // Update selectedClubsMap with new reciprocal clubs from the response
                    if (response?.data?.results?.length > 0) {
                        const newSelectedClubs = { ...selectedClubsMap };
                        response?.data?.results.filter(club => club?.is_reciprocal_club).forEach(club => {
                            newSelectedClubs[club.club] = club;
                        });
                        console.log("update_temp", Object.keys(newSelectedClubs))
                        setSelectedClubsMap(newSelectedClubs);
                    }
                }
            });
        } else {
            affiliatedListingClub({
                page: page + 1,
                limit: limit,
                id: userInfo?.is_superuser ? club?.value || '' : userInfo?.club_details[0]?.club_id,
                country: userInfo?.is_superuser ? club?.country || '': userInfo?.club_details[0]?.country,
                search: debouncedSearch,
            })(response => {
                if (response?.status) {
                    // Reciprocal ids list
                    setReciprocalIds(response?.reciprocal_ids || []);
                    // Here we keep the append behavior for pagination
                    setData(prevData => [...prevData, ...response?.data?.results]);
                    setHasMore(response?.data?.results?.length > 0);
                    
                    // Update selectedClubsMap with new reciprocal clubs from the response
                    if (response?.data?.results?.length > 0) {
                        const newSelectedClubs = { ...selectedClubsMap };
                        response?.data?.results.filter(club => club?.is_reciprocal_club).forEach(club => {
                            newSelectedClubs[club.club] = club;
                        });
                        setSelectedClubsMap(newSelectedClubs);
                    }
                }
            });
        }

        setPage((prevPage) => prevPage + 1);
    };

    const handleClubSelect = (club) => {
        if(userInfo?.is_superuser) {
            setSelectedClubsMap(prev => {
                const newMap = { ...prev };                
                
                if (newMap[club.club] && newMap[club.club]?.id === club.id) {
                    
                    setUnselectedIds([...unselectedIds, newMap[club.club]?.id]);
                    // If already selected, remove it
                    delete newMap[club.club];
                    
                } else {
                    // If not selected, add it
                    newMap[club.club] = club;
                    // Remove ID from unselectedIds if it exists
                    if(Array.isArray(unselectedIds) && unselectedIds?.length){
                        setUnselectedIds(prevIds => prevIds.filter(id => id !== club.id));
                    }
                }
                return newMap;
            });
        }
    };

    const handleSelectAll = () => {
        if (getSelectedClubsArray().length === data.length) {
            // If all visible clubs are selected, deselect all
            setSelectedClubsMap({});
        } else {
            // Otherwise, select all visible clubs
            const newSelectedClubs = { ...selectedClubsMap };
            data.forEach(club => {
                newSelectedClubs[club.club] = club;
            });
            setSelectedClubsMap(newSelectedClubs);
        }
    };

    const isClubSelected = (club) => {
        if(userInfo?.is_superuser) {
            return selectedClubsMap[club.club] && selectedClubsMap[club.club]?.id === club?.id;
        }
        return false;
    };

    let typingTimer;
    const loadOptionClub = inputValue => {
        return new Promise((resolve, reject) => {
          clearTimeout(typingTimer);
          typingTimer = setTimeout(() => {
            if (inputValue.length >= 0) {
                listAllClubs({ page: 1, limit: 10, dropdown_search: inputValue })(response => {
                if (response?.status) {
                  resolve(response?.data?.results?.map(item => ({
                    value: item.club,
                    label: item.club_name,
                    country: item.country
                  })));
                }
              })
            }
            else {
              resolve([])
            }
          }, 300);
        })
    };

    return (
        <div className='member-stats-section'>
            <div className='page-details'>
                <div className='section-top d-flex align-items-center justify-content-between gap-2 '>
                    {userInfo?.is_superuser ? 
                        <div className='d-flex align-items-center gap-2 '>
                            <div className='left'>Choose Club</div>
                            <AsyncSelect
                                placeholder="Select..."
                                isClearable
                                value={club}
                                onChange={(selectedClub) => {
                                    setclub(selectedClub);
                                    setPage(1);
                                    setSelectedClubsMap({}); 
                                }}
                                defaultOptions={clubDropdown}
                                loadOptions={loadOptionClub}
                                styles={selectStyle}
                            />
                        </div>
                    : 
                    <div className='d-flex align-items-center gap-2'>
                        <div className='left'>Affiliated Clubs</div>
                        <div className='club_title_box'>
                        <p className='club_name_affilifated'>
                            Club Name: {userInfo?.club_details?.[0]?.club_name || "N/A"}
                        </p>
                        </div>
                    </div>
                    }
                    <div>
                        {(userInfo?.is_superuser && !club) &&
                        <button className='btn-type-3' onClick={()=>navigate(CLUB_ONBOARDIN)}>Add New Club</button>
                        }
                    </div>
                </div>

                <div className='affiliated_club_listng_main'>
                    <div className='affiliated_club_wraper'>
                        <p className='affiliated_club_head'>Affiliated Clubs</p>
                        <div className="search-container">
                            <input
                                type="text"
                                className="affiliated_search"
                                placeholder="Search Clubs"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            {search && (
                                <button className="clear-button" onClick={() => setSearch("")}>
                                    &times;
                                </button>
                            )}
                        </div>

                        <div className='listing_clubs' id="scroll-div" >
                        {userInfo?.is_superuser && 
                            <label className='custom-checkbox'>
                                <input 
                                    type='checkbox' 
                                    checked={getSelectedClubsArray().length === data.length && data.length > 0}
                                    onChange={handleSelectAll}
                                />
                                <span className='checkmark'></span>
                                All
                            </label>
                        }
                            <InfiniteScroll
                                dataLength={data.length}
                                next={loadMoreClubs}
                                hasMore={data?.length < totalRows}
                                scrollableTarget="scroll-div"
                            >
                                <div className="club-container" >
                                    {data?.map((club, index) => {
                                        const isSelected = isClubSelected(club);
                                        return (
                                            <div
                                                key={index}
                                                className={`club-card ${userInfo?.is_superuser && isSelected ? 'selected-club' : ''}`}
                                                onClick={() => handleClubSelect(club)}
                                            >
                                                <div className="d-flex justify-content-between align-items-start"> 
                                                    <div>
                                                        <h3 style={{ wordBreak: 'break-word', overflowWrap: 'break-word',textWrap:"wrap" }}>{club.club_name}</h3>
                                                        <p style={{ wordBreak: 'break-word', overflowWrap: 'break-word',textWrap:"wrap" }}>{club.city_name}</p>
                                                    </div>
                                                    {isSelected && (
                                                        <div className="selection-indicator">✓</div>
                                                    )}
                                                    
                                                </div>
                                                <div className='d-flex justify-content-start gap-2'>
                                                        <p className='m-0' style={{color:club?.status == "True" ? "green" : "red"}}>Active: {club?.status == "True" ? "Yes" : "No"}</p>
                                                        <div className='vr'></div>
                                                        <p className='m-0' style={{color:club?.is_publish ? "green" : "red"}}>Published: {club?.is_publish ? "Yes" : "No"}</p>
                                                    </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </InfiniteScroll>
                            {pending ? (
                                <div className="text-center">Loading...</div>
                                ) : (
                                Array.isArray(data) && !data.length && (
                                    <div className="text-center">No affiliated clubs found.</div>
                                )
                            )}
                        </div>
                        {userInfo?.is_superuser && 
                        <div className='d-flex justify-content-end gap-2 p-2'>
                            <button className='btn-type-3' onClick={() => setSelectedClubsMap({})}>Cancel</button>
                            <button 
                                className='btn-type-7' 
                                onClick={handleSubmit}
                                disabled={isSubmitDisabled() || loading}
                            >
                               {loading ? 'Submitting...' : `Submit`}
                            </button>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AffiliatedClubs;